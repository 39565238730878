var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow overline",on:{"click":function($event){_vm.showSequences = !_vm.showSequences}}},[_vm._v(" Protein Sequences ")]),_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showSequences = !_vm.showSequences}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-details "+_vm._s(_vm.showSequences ? "mdi-rotate-180" : "")+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.showSequences ? "Hide" : "See")+" Sequences")])])],1)],1),_c('v-expand-transition',[(
        (_vm.fancy_protein_reference || _vm.fancy_protein_predicted) && _vm.showSequences
      )?_c('v-sheet',[_c('div',{staticClass:"overline"},[_vm._v("AFFECTED PROTEIN REFERENCE SEQUENCE")]),_c('v-row',[_c('v-col',{staticClass:"grow",staticStyle:{"overflow-x":"auto"}},[_c('div',{staticClass:"protein-seq"},[_c('pre',{domProps:{"innerHTML":_vm._s(this.fancy_protein_reference)}})])]),_c('v-col',{staticClass:"shrink",attrs:{"align-self":"start"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.protein.reference),expression:"protein.reference"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,3279279869)},[_c('span',[_vm._v("Copy the reference protein sequence")])])],1)],1),_c('div',{staticClass:"overline"},[_vm._v("AFFECTED PROTEIN PREDICTED SEQUENCE")]),_c('v-row',[_c('v-col',{staticClass:"grow",staticStyle:{"overflow-x":"auto"}},[_c('div',{staticClass:"protein-seq"},[_c('pre',{domProps:{"innerHTML":_vm._s(this.fancy_protein_predicted)}})])]),_c('v-col',{staticClass:"shrink",attrs:{"align-self":"start"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.protein.predicted),expression:"protein.predicted"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2755245533)},[_c('span',[_vm._v("Copy the predicted protein sequence")])])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }