import { render, staticRenderFns } from "./ViewVariantsCore.vue?vue&type=template&id=10c64763&scoped=true&"
import script from "./ViewVariantsCore.vue?vue&type=script&lang=js&"
export * from "./ViewVariantsCore.vue?vue&type=script&lang=js&"
import style0 from "../assets/main.css?vue&type=style&index=0&id=10c64763&scoped=true&lang=css&"
import style1 from "./ViewVariantsCore.vue?vue&type=style&index=1&id=10c64763&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c64763",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDivider,VIcon,VListItemAction,VTooltip})
