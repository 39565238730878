import { render, staticRenderFns } from "./Related.vue?vue&type=template&id=d4b6f4f6&scoped=true&"
import script from "./Related.vue?vue&type=script&lang=js&"
export * from "./Related.vue?vue&type=script&lang=js&"
import style0 from "../assets/main.css?vue&type=style&index=0&id=d4b6f4f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4b6f4f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VProgressLinear,VSubheader})
