var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('h1',{staticClass:"display-1 mt-10"},[_vm._v("Normalizer")]),_c('v-expansion-panels',{attrs:{"flat":"","hover":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-help","disable-icon-rotate":""}},[_vm._v("Normalize a variant description.")]),_c('v-expansion-panel-content',{attrs:{"color":"grey lighten-5"}},[_c('p',{staticClass:"mt-5"},[_vm._v(" The Normalizer accepts as input an interpretable description and provides as primary output its canonical ("),_c('a',{attrs:{"href":"https://hgvs-nomenclature.org/","target":"_blank"}},[_vm._v("HGVS")]),_vm._v(") description. ")]),_c('h4',{staticClass:"mt-5"},[_vm._v("Consequences")]),_c('p',[_vm._v(" If possible, consequences at the DNA, RNA and protein levels are presented. ")])])],1)],1),_c('v-sheet',{staticClass:"pa-5 mt-5",attrs:{"elevation":"2"}},[_c('v-row',{staticClass:"pt-1 pr-0"},[_c('v-spacer'),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","left":"","content-class":"elevation-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.switchMode()}}},[_vm._v(_vm._s(_vm.getSwitchText()))])],1)],1)],1)],1),(_vm.mode == 'hgvs')?_c('v-row',{staticClass:"pt-5 pr-5 pl-5"},[_c('v-text-field',{ref:"refInputDescriptionTextBox",staticClass:"pa-0 ma-0",attrs:{"rules":_vm.rules,"label":_vm.inputDescriptionTextBoxLabel,"clearable":true,"autofocus":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$router.push({
                name: 'Normalizer',
                params: { descriptionRouter: _vm.inputDescriptionTextBox },
              })}},model:{value:(_vm.inputDescriptionTextBox),callback:function ($$v) {_vm.inputDescriptionTextBox=$$v},expression:"inputDescriptionTextBox"}})],1):_vm._e(),(_vm.mode == 'hgvs')?_c('v-row',{staticClass:"pl-5 pr-5 mt-2"},[_c('div',{staticClass:"examples-list"},[_c('span',{staticClass:"example-text"},[_vm._v("Examples:")]),_vm._l((_vm.descriptionExamples),function(example,index){return _c('span',{key:index,staticClass:"example-item",on:{"click":function($event){$event.preventDefault();return _vm.selectDescriptionExample(index)}}},[_vm._v(_vm._s(example))])}),_c('v-menu',{attrs:{"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"example-link",attrs:{"color":"success"}},'span',attrs,false),on),[_vm._v(" ... ")])]}}],null,false,4273339884)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox =
                        'NG_012337.3(NM_003002.4):c.[53-10del;274G>T]';
                      _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NG_012337.3(NM_003002.4):c.[53-10del;274G>T]")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox =
                        'NC_000011.10(NM_003002.4):c.274del';
                      _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NC_000011.10(NM_003002.4):c.274del")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox = 'NG_012337.3:7124:G:T';
                      _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NG_012337.3:7124:G:T")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox = 'NM_003002.4:308:1:';
                      _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NM_003002.4:308:1:")])],1)],1)],1)],2)]):_vm._e(),(_vm.mode == 'sequence')?_c('v-row',{staticClass:"pt-5 pr-5 pl-5"},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"rules":_vm.rules,"label":"Reference Sequence","clearable":true},model:{value:(_vm.sequence),callback:function ($$v) {_vm.sequence=$$v},expression:"sequence"}})],1):_vm._e(),(_vm.mode == 'sequence')?_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-text-field',{ref:"refInputDescriptionTextBox",staticClass:"pa-0 ma-0",attrs:{"rules":_vm.rules,"label":"Variants","clearable":true},model:{value:(_vm.inputDescriptionTextBox),callback:function ($$v) {_vm.inputDescriptionTextBox=$$v},expression:"inputDescriptionTextBox"}})],1):_vm._e(),(_vm.mode == 'sequence')?_c('v-row',{staticClass:"pl-5"},[_c('div',{staticClass:"examples-list"},[_c('span',{staticClass:"example-link",on:{"click":function($event){return _vm.setSequenceExample()}}},[_vm._v("Example")])])]):_vm._e(),_c('v-row',{staticClass:"pl-5 pb-5"},[_c('v-btn',{ref:"normalize",staticClass:"mt-5",attrs:{"color":"primary","disabled":!_vm.valid,"to":{
              name: 'Normalizer',
              params: { descriptionRouter: _vm.inputDescriptionTextBox },
              query: _vm.getParams(),
            }}},[_vm._v(" Normalize ")])],1)],1),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loadingOverlay}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":""}})],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-5",on:{"click":function($event){_vm.loadingOverlay = false}}},[_vm._v(" Cancel ")])],1)]),(_vm.isNormalized())?_c('v-alert',{ref:"successAlert",staticClass:"mt-10 mb-0",attrs:{"elevation":"2","prominent":"","tile":"","color":_vm.getNormalizedColor(),"type":"success"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('Description',{attrs:{"description":_vm.response.normalized_description,"tooltip":_vm.response.normalized_description != _vm.inputDescription
                  ? 'Different than the input description'
                  : 'Same as the input description',"css_class":_vm.response.normalized_description != _vm.inputDescription ||
                _vm.response.infos
                  ? 'corrected-description-link-reverse'
                  : 'ok-description-link-reverse',"to_name":'Normalizer',"to_params":{
                descriptionRouter: _vm.response.normalized_description,
              },"to_query":_vm.getParams(),"tag":this.response.tag}})],1),(_vm.infoMessages())?_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showCorrections = !_vm.showCorrections}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-details "+_vm._s(_vm.showCorrections ? "mdi-rotate-180" : "")+" ")])],1)]}}],null,false,1253185149)},[_c('span',[_vm._v(_vm._s(_vm.showCorrections ? "Hide" : "See")+" Details")])])],1):_vm._e()],1)],1):_vm._e(),(_vm.connectionErrors)?_c('v-alert',{staticClass:"mt-10",attrs:{"prominent":"","type":"error","tile":"","elevation":"2","icon":"mdi-network-off-outline","color":"grey darken-4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.connectionErrors.details)+" ")])],1)],1):_vm._e(),(_vm.response && _vm.response.errors)?_c('v-alert',{staticClass:"mt-10 mb-0",attrs:{"prominent":"","type":"error","tile":"","elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow overline"},[_vm._v("Description could not be interpreted")]),(_vm.infoMessages())?_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showCorrections = !_vm.showCorrections}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-details "+_vm._s(_vm.showCorrections ? "mdi-rotate-180" : "")+" ")])],1)]}}],null,false,1253185149)},[_c('span',[_vm._v(_vm._s(_vm.showCorrections ? "Hide" : "See")+" Details")])])],1):_vm._e()],1)],1):_vm._e(),_c('v-expand-transition',[((_vm.infoMessages() && _vm.showCorrections) || _vm.errorsEncountered())?_c('v-sheet',{attrs:{"elevation":"2"}},[_c('v-expand-transition',[(_vm.infoMessages() && _vm.showCorrections)?_c('v-sheet',{ref:"refCorrections",staticClass:"pt-5 pr-10 pb-5 pl-10",attrs:{"color":"grey lighten-5"}},[(_vm.correctionsPerformed())?_c('div',{staticClass:"overline"},[_vm._v(" Input Description ")]):_vm._e(),(_vm.correctionsPerformed())?_c('div',{class:_vm.getInputDescriptionClass()},[_vm._v(" "+_vm._s(_vm.inputDescription)+" ")]):_vm._e(),(_vm.response.infos)?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Corrections / Info Messages")]),_vm._l((_vm.response.infos),function(info,index){return _c('v-alert',{key:index,staticClass:"ml-2",attrs:{"color":"light-blue lighten-5","tile":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.getMessage(info))+" "),(info.code == 'IMRNAGENOMICDIFFERENCE')?_c('span',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
                        name: 'Mapper',
                        query: {
                          description: _vm.response.normalized_description,
                          reference_id: info.reference_id,
                          selector_id: info.selector_id,
                          slice_to: 'transcript',
                          not_run: false,
                        },
                      }}},[_vm._v("Check it using the Mapper tool.")])],1):_vm._e()])})],2):_vm._e(),(_vm.correctionsPerformed() && _vm.showCorrections)?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Corrected Description")]),_c('div',{class:_vm.getCorrectedDescriptionClass()},[_vm._v(" "+_vm._s(_vm.response.corrected_description)+" ")])]):_vm._e()]):_vm._e()],1),(_vm.errorsEncountered())?_c('v-sheet',{staticClass:"pt-10 pr-10 pb-8 pl-10",attrs:{"color":"red lighten-5"}},_vm._l((_vm.response.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"color":"red lighten-1","tile":"","border":"left","dark":""}},[(_vm.syntaxError())?_c('div',[_c('SyntaxError',{attrs:{"errorModel":_vm.getSyntaxError()}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.getMessage(error))+" ")])])}),1):_vm._e()],1):_vm._e()],1),(_vm.response && _vm.response.chromosomal_descriptions)?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":"","value":_vm.genomic_open}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Chromosomal Descriptions")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},_vm._l((_vm.response.chromosomal_descriptions),function(pair,index){return _c('div',{key:index,staticClass:"ml-4"},[_c('span',[_vm._v(_vm._s(pair.assembly))]),(pair.c)?_c('Description',{attrs:{"description":pair.c,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
                  descriptionRouter: pair.c,
                },"tag":pair.tag}}):_vm._e(),(pair.g)?_c('Description',{attrs:{"description":pair.g,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{ descriptionRouter: pair.g }}}):_vm._e(),(pair.errors)?_c('v-expansion-panels',{attrs:{"multiple":"","flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline red--text text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])]},proxy:true}],null,true)},[_vm._v("Unsuccessful mapping ")]),_c('v-expansion-panel-content',_vm._l((pair.errors),function(error,index){return _c('v-alert',{key:index,staticClass:"mt-5",attrs:{"color":"red lighten-1","tile":"","border":"left","dark":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.getMessage(error))+" ")])])}),1)],1)],1):_vm._e()],1)}),0)],1)],1):_vm._e(),(_vm.response && (_vm.response.rna || _vm.response.protein))?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":"","value":_vm.consequences_open}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Biological Information Transfer")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},[(
                _vm.response &&
                _vm.response.equivalent_descriptions &&
                _vm.response.equivalent_descriptions.g
              )?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Genomic Description")]),_c('Description',{attrs:{"description":_vm.response.equivalent_descriptions.g[0].description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
                  descriptionRouter:
                    _vm.response.equivalent_descriptions.g[0].description,
                }}})],1):_vm._e(),(_vm.response.rna && _vm.response.rna.errors)?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Predictions")]),_c('v-sheet',_vm._l((_vm.response.rna.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"color":"red lighten-1","tile":"","border":"left","dark":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.getMessage(error))+" ")])])}),1)],1):_vm._e(),(_vm.response.rna && _vm.response.rna.description)?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Predicted RNA Description")]),_c('Description',{attrs:{"description":_vm.response.rna.description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{ descriptionRouter: _vm.response.rna.description }}})],1):_vm._e(),(_vm.response.protein && _vm.response.protein.description)?_c('div',[_c('div',{staticClass:"overline"},[_vm._v("Predicted Protein Description")]),_c('Description',{attrs:{"description":_vm.response.protein.description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
                  descriptionRouter: _vm.response.protein.description,
                }}}),(_vm.response.protein && _vm.response.protein.description)?_c('AffectedProtein',{attrs:{"protein":_vm.response.protein}}):_vm._e()],1):_vm._e()])],1)],1):_vm._e(),(this.showTranscripts())?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":"","value":_vm.equivalent_open}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Other Annotated Transcripts")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},_vm._l((_vm.getEquivalentDescriptions(
                _vm.response.equivalent_descriptions
              )),function(equivalent){return _c('v-sheet',{key:equivalent.type},[_c('div',{staticClass:"overline"},[_vm._v(_vm._s(equivalent.type))]),_vm._l((equivalent.descriptions),function(e_d,index){return _c('v-sheet',{key:index},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pa-2 ma-1",attrs:{"color":hover ? 'grey lighten-3' : ''}},[_c('Description',{attrs:{"description":e_d.description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
                        descriptionRouter: e_d.description,
                      },"tag":e_d.tag,"selector":e_d.selector}})],1)]}}],null,true)}),(index != equivalent.descriptions.length - 1)?_c('v-divider'):_vm._e()],1)})],2)}),1)],1)],1):_vm._e(),(this.showEquivalentProtein())?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":"","value":_vm.equivalent_open}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Equivalent Description")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},[_c('Description',{attrs:{"description":this.response.equivalent_descriptions.p[0].description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
                descriptionRouter: this.response.equivalent_descriptions.p[0]
                  .description,
              }}})],1)],1)],1):_vm._e(),(_vm.response && _vm.response.back_translated_descriptions)?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":"","value":_vm.back_translated_open}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Back Translated Descriptions")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},_vm._l((_vm.response.back_translated_descriptions),function(equivalentDescription,index){return _c('div',{key:index},[_c('router-link',{staticClass:"ok-description-link",attrs:{"to":{
                  name: 'Normalizer',
                  params: { descriptionRouter: equivalentDescription },
                }}},[_vm._v(_vm._s(equivalentDescription))])],1)}),0)],1)],1):_vm._e(),(_vm.showReferenceInformation())?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Reference Sequence Information")]),_c('v-expansion-panel-content',[_c('ReferenceInformation',{attrs:{"model":this.response.corrected_model}}),(_vm.response && _vm.response.selector_short)?_c('SelectorShort',{attrs:{"selector":_vm.response.selector_short}}):_vm._e()],1)],1)],1):_vm._e(),(
          _vm.response &&
          _vm.response.normalized_description &&
          _vm.response.normalized_model &&
          _vm.response.normalized_model.reference &&
          !['p'].includes(_vm.response.normalized_model.coordinate_system)
        )?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("Related reference sequences")]),_c('v-expansion-panel-content',{staticClass:"pt-5"},[_c('Related',{attrs:{"model":this.response.normalized_model,"description":this.response.normalized_description}})],1)],1)],1):_vm._e(),(
          _vm.response &&
          _vm.response.normalized_description &&
          _vm.response.normalized_model
        )?_c('v-expansion-panels',{staticClass:"mt-5 mb-5",attrs:{"focusable":"","hover":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"overline"},[_vm._v("View Variants Sequence Overview")]),_c('v-expansion-panel-content',{staticClass:"pt-2 pb-2"},[_c('ViewVariants',{attrs:{"description":this.response.normalized_description,"only_variants":this.response.only_variants,"sequence":this.response.sequence,"d_type":'normalized',"selector":this.response.selector_short,"c_s_var":_vm.get_c_s_var(),"c_s_seq":_vm.get_c_s_seq()}})],1)],1)],1):_vm._e(),(_vm.response)?_c('v-expansion-panels',{staticClass:"mt-10 mb-10",attrs:{"focusable":"","hover":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Raw Response")]),_c('v-expansion-panel-content',[_c('JsonPretty',{attrs:{"summary":_vm.response}})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }