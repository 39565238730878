var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-1 mt-10 ml-10 mb-5"},[_vm._v("Welcome!")]),_c('p',{staticClass:"ml-10"},[_vm._v(" The Mutalyzer tool suite is designed to be of help when working with "),_c('a',{attrs:{"href":"https://www.hgvs.org/"}},[_vm._v("HGVS")]),_vm._v(" sequence variant "),_c('a',{attrs:{"href":"https://varnomen.hgvs.org/"}},[_vm._v("nomenclature")]),_vm._v(" descriptions. Feedback is appreciated. ")])])],1),_c('v-row',{staticClass:"pl-10 pr-10 mb-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-swing pb-7",attrs:{"color":"grey lighten-5","elevation":hover ? 4 : 2}},[_c('v-card-text',{staticClass:"pt-6 mb-0 pb-0 pointer",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.$router.push({ name: 'Normalizer' })}}},[_c('h3',{staticClass:"display-1 font-weight-light blue--text mb-2"},[_vm._v(" Normalizer ")]),_c('div',{staticClass:"font-weight-light title mb-2"},[_vm._v(" Takes a variant description as input and checks whether it is correct. ")])]),_c('v-flex',[_c('v-row',{staticClass:"pr-5 pl-5"},[_c('v-col',{staticClass:"pb-2"},[_c('div',{staticClass:"font-weight-light mb-2"},[_vm._v(" Examples: "),_vm._l((_vm.descriptionExamples),function(example,index){return _c('span',{key:index,staticClass:"example-item",on:{"click":function($event){$event.preventDefault();return _vm.selectDescriptionExample(index)}}},[_vm._v(_vm._s(example))])}),_c('v-menu',{attrs:{"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"example-link",attrs:{"color":"success"}},'span',attrs,false),on),[_vm._v(" ... ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox =
                                  'NG_012337.3(NM_003002.4):c.[53-10del;274G>T]';
                                _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NG_012337.3(NM_003002.4):c.[53-10del;274G>T]")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"example-item",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();_vm.inputDescriptionTextBox =
                                  'NC_000011.10(NM_003002.4):c.274del';
                                _vm.$refs.refInputDescriptionTextBox.focus();}}},[_vm._v("NC_000011.10(NM_003002.4):c.274del")])],1)],1)],1)],2)])],1),_c('v-row',{staticClass:"ml-5 mr-5 mt-0 pl-0"},[_c('v-text-field',{ref:"refInputDescriptionTextBox",staticClass:"mr-5",attrs:{"background-color":"grey lighten-5","label":"HGVS Description","clearable":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$router.push({
                        name: 'Normalizer',
                        params: {
                          descriptionRouter: _vm.inputDescriptionTextBox,
                        },
                      })}},model:{value:(_vm.inputDescriptionTextBox),callback:function ($$v) {_vm.inputDescriptionTextBox=$$v},expression:"inputDescriptionTextBox"}}),_c('v-btn',{ref:"normalize",staticClass:"mt-4",attrs:{"tile":"","color":"primary","to":{
                      name: 'Normalizer',
                      params: {
                        descriptionRouter: _vm.inputDescriptionTextBox,
                      },
                    }}},[_vm._v(" Normalize ")])],1)],1)],1)]}}])})],1)],1),_c('v-row',{staticClass:"pl-10 pr-10"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-swing",attrs:{"color":"grey lighten-4","elevation":hover ? 4 : 2,"to":{ name: 'Mapper' }}},[_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('h3',{staticClass:"display-1 font-weight-light blue--text mb-2"},[_vm._v(" Mapper ")]),_c('div',{staticClass:"font-weight-light title mb-2"},[_vm._v(" Maps an HGVS description to another reference sequence. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-swing",attrs:{"color":"grey lighten-3","elevation":hover ? 4 : 2,"to":{ name: 'DescriptionExtractor' }}},[_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('h3',{staticClass:"display-1 font-weight-light blue--text mb-2"},[_vm._v(" Description Extractor ")]),_c('div',{staticClass:"font-weight-light title mb-2"},[_vm._v(" Generates the HGVS variant description from an observed and a reference sequence. ")])])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }