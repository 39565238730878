var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[(_vm.tooltip && _vm.to_name)?_c('v-col',{staticClass:"grow"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.to_name)?_c('router-link',{class:_vm.css_class,attrs:{"to":{
              name: _vm.to_name,
              params: _vm.to_params,
              query: _vm.to_query,
            }}},[_c('div',_vm._g(_vm._b({class:_vm.css_class},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_c('div',_vm._g(_vm._b({class:_vm.css_class},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.description)+" ")])]}}],null,false,722339948)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1):_c('v-col',{staticClass:"grow"},[(_vm.to_name)?_c('router-link',{class:_vm.css_class,attrs:{"to":{
          name: _vm.to_name,
          params: _vm.to_params,
          query: _vm.to_query,
        }}},[_vm._v(_vm._s(_vm.description))]):_c('div',{class:_vm.css_class},[_vm._v(" "+_vm._s(_vm.description)+" ")])],1),(_vm.tag)?_c('v-col',{staticClass:"shrink"},[(this.css_class == 'ok-description-link')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"green darken-4","text-color":"green darken-4","outlined":"","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tag.details)+" ")])]}}],null,false,2682550471)},[_c('span',[_vm._v(_vm._s(this.tag.id)+" is the representative transcript as part of the MANE project.")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"white","text-color":"white","outlined":"","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tag.details)+" ")])]}}],null,false,87019463)},[_c('span',[_vm._v(_vm._s(this.tag.id)+" is the representative transcript as part of the MANE project.")])])],1):_vm._e(),(_vm.selector && !_vm.mapping_checked)?_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.map()}}},'v-btn',attrs,false),on),[_vm._v(" Map ")])]}}],null,false,3262523910)},[_c('span',[_vm._v(" Map this description to "+_vm._s(this.selector.id)+". ")])])],1):_vm._e(),_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.description),expression:"description"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Copy")])])],1)],1),(this.mapped_description)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"0","color":"grey lighten-5"}},[_c('v-card-subtitle',{staticClass:"grey--text ml-2"},[_vm._v("Mapped description to "+_vm._s(this.selector.id))]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{class:_vm.css_class},[_vm._v(" "+_vm._s(this.mapped_description)+" ")])]),_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.mapped_description),expression:"mapped_description"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,1492393821)},[_c('span',[_vm._v("Copy")])])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.mapping_errors)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"grey--text ml-2"},[_vm._v(" Mapping description to "+_vm._s(this.selector.id)+" was unsuccessful. ")])])],1):_vm._e(),(_vm.mapping_errors)?_c('v-row',[_c('v-col',{staticClass:"pl-4 pr-4"},_vm._l((_vm.mapping_errors.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"color":"red lighten-1","tile":"","border":"left","dark":"","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.getMessage(error))+" ")])])}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }